<template>
<div class="modal-scope">
    <img :src="novel.novel_cover" class="cover mb-4">
    <a-alert class="mb-3 ml-2 mr-2" v-if="form.chapter_start > form.chapter_last" type="error" message="ตอนเริ่มต้นต้องมากกว่าหรือเท่ากับตอนสุดท้ายเท่านั้น" banner />
    <a-alert class="mb-3 ml-2 mr-2" v-if="buyed == 'success'" type="success" message="การทำรายการสำเร็จ" banner />
    <a-alert class="mb-3 ml-2 mr-2" v-if="buyed == 'fail'" type="warning" message="การทำรายการล้มเหลว" banner />
    <b-form class="center" inline>
        <label class="sr-only" for="first_chapter_ep">ตอนเริ่มต้น</label>
        <b-form-input
            id="first_chapter_ep"
            v-model="form.chapter_start"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="ตอนเริ่มต้น"
            :min="novel.chapter_ep_first"
            :max="novel.chapter_ep_last"
            type="number"
            @change="strtonumber()"
        >
        </b-form-input>
        <span>ถึง</span>
        <label class="sr-only" for="last_chapter_ep">ตอนสุดท้าย</label>
        <b-form-input
            id="last_chapter_ep"
            v-model="form.chapter_last"
            class="mb-2 ml-sm-2 mb-sm-0"
            placeholder="ตอนสุดท้าย"
            :min="novel.chapter_ep_first"
            :max="novel.chapter_ep_last"
            type="number"
            @change="strtonumber()"
        >
        </b-form-input>
    </b-form>
    <hr>
    <a-button class="center mb-3" type="primary" @click="calculate_price()">
      คำนวณราคา
    </a-button>
    <a-alert class="mb-3 ml-2 mr-2" v-if="userInfo.member_is_admin == 1 || total_price == 0" type="info" message="คุณได้ซื้อทุกตอนในช่วงนี้แล้ว" banner />
    <div v-else>
        <p v-if="total_price != null">ราคา : {{total_price | numFormat}} ทอง</p>
        <div class="myCoin mb-3">
            <table style="width:100%">
                <tr>
                    <td>ทองที่มีทั้งหมด</td>
                    <td><img src="./../../assets/price.png" width="18px" /> <span>{{member_gold| numFormat}}</span></td>
                </tr>
            </table>
        </div>
        <b-alert :show="error" variant="warning">{{err_text}}</b-alert>
        <div v-if="ishide">
            <p class="warn">ยกเลิกการขายแล้ว</p>
        </div>
        <div v-if="total_price != null">
            <div v-if="goldEnough">
                <a-button type="danger" @click="buy_range()" :loading="is_loading">ซื้อเลย</a-button>
            </div>
            <div class="text-center" v-else>
                <p class="warn">ทองของคุณไม่เพียงพอ</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import {
    mapActions,
    mapGetters
} from 'vuex'

export default {
    props: ['novel'],
    data() {
        return {
            goldEnough: false,
            error: false,
            err_text: "",
            form: {
                chapter_start: 0,
                chapter_last: 1,
            },
            total_price: null,
            is_loading: true,
            ishide: false,
            member_gold: 0,
            buyed: null
        }
    },
    async mounted() {
        await this.initial()
        if(this.novel.ishide == true){
            this.ishide = true
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'GET_USER_INFO'
        })
    },
    methods: {
        ...mapActions(['SET_MEMBER_GOLD','SET_USER_INFO']),
        initial(){
            this.form.chapter_last = parseFloat(this.novel.chapter_ep_last)
            this.form.chapter_start = parseFloat(this.novel.chapter_ep_first)
            this.workerupdateUser()
        },
        async strtonumber(){
            this.form.chapter_last = await parseFloat(this.form.chapter_last)
            this.form.chapter_start = await parseFloat(this.form.chapter_start)
        },
        async workerupdateUser() {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            try {
                await axios.post('https://api2.novelrealm.com/module/profile&submodule=info', formData)
                    .then((res) => {
                        //console.log(res.data)
                        if (res.data.status == 200) {
                            this.SET_USER_INFO({
                                auth_token: this.userInfo.auth_token,
                                auth_memberid: this.userInfo.auth_memberid,
                                displayname: res.data.member_displayname,
                                member_gold: res.data.member_gold,
                                member_profile_url: res.data.member_profile_url,
                                member_is_admin: res.data.member_is_admin,
                                member_is_publisher: res.data.member_is_publisher
                            })
                            this.member_gold = this.userInfo.member_gold
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async calculate_price(){
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid)
            formData.append('auth_reference', this.userInfo.auth_token)
            formData.append('novel_id', this.novel.novel_id)
            formData.append('chapter_ep_start', this.form.chapter_start)
            formData.append('chapter_ep_final', this.form.chapter_last)
            try {
                await axios.post('https://api2.novelrealm.com/module/chapter&submodule=buyrange', formData)
                    .then((res) => {
                        //console.log(res.data)
                        this.total_price = res.data.chapter_buy_range_price
                        this.member_gold = res.data.member.member_gold
                        if (parseInt(this.member_gold) >= parseInt(this.total_price)) {
                            this.goldEnough = true
                        }
                        this.is_loading = false
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async buy_range(){
            this.is_loading = true;
            this.buyed = '';
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid)
            formData.append('auth_reference', this.userInfo.auth_token)
            formData.append('novel_id', this.novel.novel_id)
            formData.append('chapter_ep_start', this.form.chapter_start)
            formData.append('chapter_ep_final', this.form.chapter_last)
            formData.append('action', 'buy')
            try {
                await axios.post('https://api2.novelrealm.com/module/chapter&submodule=buyrange', formData)
                    .then((res) => {
                        //console.log(res.data)
                        if(res.data.buy_status_total == true){
                            this.buyed = 'success';
                        }
                        else{
                            this.buyed = 'fail';
                        }
                        this.SET_MEMBER_GOLD(res.data.member.member_gold)
                        this.is_loading = false;
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
    }
}
</script>

<style scoped>
.modal-scope {
    text-align: center;
}

.center{
    margin: auto;
    width: fit-content;
}

.cover {
    border-radius: 10px;
    width: 6rem;
}

.myCoin {
    padding: 5px;
    border: 1px solid #EE806B;
    border-radius: 7px;
    text-align: center;
    width: 100%;
}

.warn {
    font-size: 14px;
    color: #ffffff;
    padding: 0px 5px;
    border-radius: 5px;
    background-color: #042446;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.addCoin {
    color: #ffffff;
    font-weight: bold;
    padding: 7px 20px 7px 20px;
    border-radius: 8px;
    background-color: #EE806B;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.addCoin:hover {
    background-color: rgb(235, 116, 92);
    cursor: pointer;
}
</style>
